function on_toggle_main_search() {
	var $mainSearch = $('#mainSearchJS');
	var $mainWrapper = $('#mainWrapperJS');
	var $mainSearchText = $('#mainSearchTextJS');

	$mainSearch.on('show.bs.collapse', function () {
		$mainWrapper.addClass('searchOpen');
	}).on('shown.bs.collapse', function () {
		$mainSearchText.focus();
	}).on('hide.bs.collapse', function () {
		$mainWrapper.removeClass('searchOpen'); 
	});

	$mainSearch.on('focusout', function (e) {
		if ($(e.relatedTarget).closest($mainSearch).length == 0) {
			$mainSearch.collapse('hide');
		}
	});
}

function close_menu_collapse_on_hamburger_hide() {
	$('#firstMenu .firstDropdownMenu').on('hidden.bs.collapse', function (e) {
		var $t = $(this);

		if ($t.is(e.target)) {
			$t.find('.collapse.show').collapse('hide'); 
		}
	});
}

function close_first_menu_collapse_on_hover_out() {
	$('#firstMenu .item').on('mouseleave', function (e) {
		setTimeout(function () {
			$(e.target).closest('.item').find('.firstDropdownMenu').collapse('hide'); 
		},350);
	});
}

$(function () {
	on_toggle_main_search();
	close_menu_collapse_on_hamburger_hide();
	close_first_menu_collapse_on_hover_out();
});