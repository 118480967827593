var $html = $('html');
var $carouselContainer = $('.autoPlayJS');
var $btnCollapse = $('.accessibilityCollapseJS');

function accessibility_toggle_control() {
	$('.btnAccessibility').each(function (index, element) {
		var $this_element = $(element);
		var added_class = $this_element.attr('data-body');
		var theme = localStorage.getItem(added_class);

		if (theme !== '') {
			$html.addClass(theme);
			if (theme !== null) {
				accessibility_selected($this_element);
			}
		}

		//stop_carousel_container(theme);

		$this_element.on('click', function () {
			$html.toggleClass(added_class);
			if ($html.hasClass(added_class)) {
				accessibility_selected($this_element);
				localStorage.setItem(added_class, added_class);
				//stop_carousel_container(added_class);
			}
			else {
				$this_element.removeClass('active');
				$this_element.attr('aria-pressed', 'false');
				localStorage.removeItem(added_class);

				/* if (theme === 'stopMovement') {
					$carouselContainer.slick('slickPlay');
				} */
			}
		});

		function accessibility_selected($this_element) {
			$this_element.addClass('active');
			$this_element.attr('aria-pressed', 'true');
		}

		/* function stop_carousel_container(added_class) {
			if (added_class === 'stopMovement' || theme == 'stopMovement') {
				$carouselContainer.slick('slickPause');
			}
		} */
	});
}

function toggle_keyboardFocus_on_keydown_or_mousedown() {
	$(document.body).on('keyup', function (e) {
		if (e.keyCode === 9 && !$html.hasClass('keyboardFocus')) {
			$html.addClass('keyboardFocus stopMovement');
			$carouselContainer.slick('slickPause');
			$btnCollapse.data('toggle', 'collapse');
		}
	}).on('mousedown', function (e) {
		if ($html.hasClass('keyboardFocus')) {
			$html.removeClass('keyboardFocus stopMovement');
			$carouselContainer.slick('slickPlay');
			$btnCollapse.data('toggle', '');
		}
	});
}

function keep_accessibility_container_open_on_click_inside() {
	$('#accessibilityMenuJS').on('click', function (e) {
		e.stopPropagation();
	});
}

function check_if_mobile() {
	if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) { }
	else {
		$('#accessibilityContainerJS').show();
	}
}

function openContainerOnFocus() {
	if ($('#accessibilityContainerJS').is(':visible')) {
		//mainNav
		var $firstMenu = $('ul.firstMenu');
		$firstMenu.find('.item .firstDropdownMenu').on('shown.bs.collapse', function () {
			var $s = $(this);
			if (!$s.find('.quickAccessForm').length) {
				$s.find('.subItem:last-child .subLink').on('blur keydown', function (e) {
					var $link = $(this);
					if (e.keyCode == 9 && !e.shiftKey) {
						if ($link.closest('.subItem').is(':last-child')) {
							setTimeout(() => {
								$s.prev('.link').click();
							}, 100);
						}
					}
				})
			} else {
				$s.find('.quickAccessForm .subBtn').on('blur keydown', function (e) {
					if (e.keyCode == 9 && !e.shiftKey) {
						setTimeout(() => {
							$s.prev('.link').click();
						}, 100);
					}
				})
			}
		});

		$firstMenu.find('.item').each(function () {
			var $item = $(this);
			$item.find('.link').on('focus', function (e) {
				var $link = $(this);
				$link.closest('.item').siblings().removeClass('focused');
				if ($link.hasClass('collapsed')) {
					setTimeout(() => {
						$link.click();
					}, 200);
				}
			}).on('blur keydown', function (e) {
				var $link = $(this);
				if (!$link.hasClass('collapsed') && e.shiftKey && e.keyCode == 9) {
					setTimeout(() => {
						$link.click();
					}, 100);
				}
			});

		})

		//userMenu
		var $userMenu = $('ul.userMenu');

		$userMenu.find('.item .headerDropdownMenu').on('shown.bs.collapse', function () {
			var $s = $(this);
			$s.find('.list:not(.innerSublinkList) > .subItem:last-child .subLink').on('blur keydown', function (e) {
				var $link = $(this);
				if (e.keyCode == 9 && !e.shiftKey) {
					if ($link.closest('.subItem').is(':last-child')) {
						setTimeout(() => {
							$s.prev('.link').click();
						}, 100);
					}
				}
			})
		});


		$userMenu.find('.item').each(function () {
			var $item = $(this);
			$item.find('.link').on('focus', function (e) {
				var $link = $(this);
				if ($link.hasClass('collapsed')) {
					setTimeout(() => {
						$link.click();
					}, 200);
				}
			}).on('blur keydown', function (e) {
				var $link = $(this);
				if ((!$link.hasClass('collapsed') && e.shiftKey && e.keyCode == 9)) {
					setTimeout(() => {
						$link.click();
					}, 100);
				}
			});

		})

		$userMenu.find('.item:last-child').find('.link').on('blur keydown', function (e) {
			var $link = $(this);
			if (!$link.next('.headerDropdownMenu').length) {
				if ((!e.shiftKey && e.keyCode == 9)) {
					setTimeout(() => {
						$link.closest('.item').siblings().each(function () {
							$(this).find('.headerDropdownMenu').collapse('hide');
						})
					}, 100);
				}
			}

		})

		//share list
		var $shareList = $('.shareList');
		$shareList.find('.subItem:last-child .subLink').on('blur keydown', function (e) {
			if (e.keyCode == 9 && !e.shiftKey) {
				$shareList.closest('.dropdown-menu').collapse('hide');
			}
		});

		var $shareBtn = $('.contentShareList .shareBtn');
		$shareBtn.on('blur keydown', function (e) {
			if ((e.keyCode == 9 && e.shiftKey)) {
				$shareList.closest('.dropdown-menu').collapse('hide');
			}
		});

		$('.bootstrap-select .btn.dropdown-toggle').each(function () {
			var $t = $(this);
			$t.on('keydown', function (e) {
				if (e.keyCode == 13 || e.keyCode == 32) {
					e.preventDefault();
					$($t.prev('.selectpicker')).selectpicker('toggle');
				}
			})
		})


		//controlMenu
		var $controlMenu = $('.controlMenu');
		$controlMenu.find('.accessibilityCollapseJS').each(function () {
			var $t = $(this);
			$t.on('focus', function (e) {
				var $link = $(this);
				if ($link.hasClass('collapsed')) {
					setTimeout(() => {
						$link.click();
					}, 200);
				}
			}).on('blur keydown', function (e) {
				var $link = $(this);
				if ((!$link.hasClass('collapsed') && e.shiftKey && e.keyCode == 9)) {
					setTimeout(() => {
						$link.click();
					}, 100);
				}
			});
		})

		$controlMenu.find('.subItem:last-child .subLink').on('blur keydown', function (e) {
			if (e.keyCode == 9 && !e.shiftKey) {
				$(this).closest('.headerDropdownMenu').collapse('hide');
			}
		});


		$(document).on('click', function (e) {
			$('.accessibilityCollapseJS').each(function () {
				var $acJS = $(this);
				$acJS.next().collapse('hide');
			});
			if(!$(e.target.closest('.mainNav')).length) {
			$firstMenu.find('.item').each(function () {
				var $item = $(this);
				$item.removeClass('focused');

			})
		}
		})
	}
}

$(function () {
	accessibility_toggle_control();
	toggle_keyboardFocus_on_keydown_or_mousedown();
	keep_accessibility_container_open_on_click_inside();
	check_if_mobile();
	openContainerOnFocus();
});