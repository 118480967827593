function on_submit_search() {
    var textHolder = $('#searchResultsTextJS');
    var textHolderVal = textHolder.val();
    var baseUrl = window.location.origin;
    var FullUrl;
    var isSearchResultsPage = window.location.href.indexOf("?term=") > -1;
    var start = 1;

    if (isSearchResultsPage) {
        search(textHolderVal, start);
    }

    $('.mainSearch .btnSubmitJS').on('click', function () {
        $t = $(this);
        textHolderVal = $t.prev().val();
        FullUrl = baseUrl + '/search-results?term=' + textHolderVal;

        if ($t.closest('#mainSearchJS').length) {
            window.open(FullUrl, '_self');
        }
        else {
            search(textHolderVal, start);
        }
    });

    $('#nextPrevContainerJS .btnContainer').on('click', function () {
        textHolder = $('#searchResultsTextJS');

        if ($(this).hasClass('next')) {
            search(textHolderVal, start += 10);
        }
        else {
            search(textHolderVal, start -= 10);
        }
    });
}

function search(term, start) {
    if (term.length == 0) {
        return;
    }
    var $searchResults = $('#searchResultsJS');
    var $resultQuantity = $searchResults.find('.resultQuantity');
    var $resultTerm = $searchResults.find('.resultTerm');
    //var $suggestionTerm = $searchResults.find('.suggestionTerm');
    var $suggestion = $searchResults.find('.suggestion');
    var $resultNotFound = $searchResults.find('.resultNotFound');
    var $resultFound = $searchResults.find('.resultFound');
    var $resultsList = $searchResults.find('.resultsList');
    var $pagerContainer = $('#nextPrevContainerJS');
    var $btnPrev = $pagerContainer.find('.prev');
    var $btnNext = $pagerContainer.find('.next');
    var itemList = [];

    window.seminar.toggleLoader(true);

    $.ajax({
        url: 'https://www.googleapis.com/customsearch/v1?key=AIzaSyD7u0zgcbkLY2bubAzRxCpUS3rU9xU0q_g&cx=014755367554753401901:s_dc-87_m4s&q=' + term + '&start=' + start,
        cache: false,
        success: function (response) {
            window.seminar.toggleLoader(false);

            is_hidden($searchResults, false);
            is_hidden($btnPrev, true);
            is_hidden($btnNext, true);
            is_hidden($suggestion, true);

            if (response.queries) {
                if (response.queries.nextPage) {
                    next = response.queries.nextPage[0].startIndex;
                    is_hidden($btnNext, false);
                }
                if (response.queries.previousPage) {
                    prev = response.queries.previousPage[0].startIndex;
                    is_hidden($btnPrev, false);
                }
            }
            if (response.queries.request[0]) {
                $resultTerm.html(response.queries.request[0].searchTerms);
            }
            if (response.items && response.items.length) {
                $resultQuantity.html(response.searchInformation.totalResults);

                for (var i = 0; i < response.items.length; i++) {
                    var item = response.items[i];
                    var ctrlResult = generate_result_control(item);

                    itemList.push(ctrlResult);
                }

                $resultsList.html(itemList.join(''));

                is_hidden($resultNotFound, true);
                is_hidden($resultFound, false);
                is_hidden($resultsList, false);
                is_hidden($pagerContainer, false);
            } else {
                is_hidden($resultNotFound, false);
                is_hidden($resultFound, true);
                is_hidden($resultsList, true);
                is_hidden($pagerContainer, true);
            }

            function generate_result_control(item) {
                return '<li class="item">'
                    + '<a href="' + item.link + '" class="link">' + item.title + '</a>'
                    + '<div class="text">' + item.htmlTitle + '</div>'
                    + '</li>'
            }

            function is_hidden(element, boolean) {
                $t = $(element);

                if (boolean) {
                    $t.addClass('hidden');
                }
                else {
                    $t.removeClass('hidden');
                }
            }

            // if (response.spelling) {
            // 	var htmlSuggestions = '<a href="'
            // 		+ ((Config.language == "ar") ? "/ar" : "")
            // 		+ "/search-results?term="
            // 		+ response.spelling.correctedQuery
            // 		+ '" class="color">'
            // 		+ response.spelling.correctedQuery
            // 		+ '</a>';

            // 	$suggestionTerm.html(htmlSuggestions);
            // 	is_hidden($suggestion, false);
            // }
        }
    }).done(function () {
        console.log('done');
    }).fail(function (ex) {
        console.log(ex);
    });
}

$(function () {
    on_submit_search();
});


// function on_submit_search() {
// 	var textHolderVal;
// 	var start = 1;

// 	$('.btnSubmitJS').on('click', function () {
// 		window.seminar.toggleLoader(true);
// 		textHolderVal = $(this).prev('.textHolder').val();
// 		search(textHolderVal, start);
// 	});
// }

// function searchApi(term, start, done) {
// 	$.ajax({
// 		url: 'https://www.googleapis.com/customsearch/v1?key=AIzaSyD7u0zgcbkLY2bubAzRxCpUS3rU9xU0q_g&cx=014755367554753401901:s_dc-87_m4s&q=' + term + '&start=' + start,
// 		cache: false,
// 		success: function (response) {
// 			var $searchResults = $('#searchResultsJS');
// 			var $resultQuantity = $searchResults.find('.resultQuantity');
// 			var $resultTerm = $searchResults.find('.resultTerm');
// 			var $suggestionTerm = $searchResults.find('.suggestionTerm');
// 			var $suggestion = $searchResults.find('.suggestion');
// 			var $resultNotFound = $searchResults.find('.resultNotFound');
// 			var $resultFound = $searchResults.find('.resultFound');
// 			var $resultsList = $searchResults.find('.resultsList');
// 			var itemList = [];
// 			var quantity = 0;

// 			window.seminar.toggleLoader(false);

// 			is_hidden($searchResults, false);
// 			is_hidden($suggestion, true);

// 			if (response.queries.request[0]) {
// 				$resultTerm.html(response.queries.request[0].searchTerms);
// 			}
// 			if (response.items && response.items.length) {
// 				$resultQuantity.html(response.searchInformation.totalResults);
// 				quantity = response.searchInformation.totalResults;

// 				for (var i = 0; i < response.items.length; i++) {
// 					var item = response.items[i];
// 					var ctrlResult = generate_result_control(item);

// 					itemList.push(ctrlResult);
// 				}
// 				$resultsList.html(itemList.join(''));

// 				is_hidden($resultNotFound, true);
// 				is_hidden($resultFound, false);
// 				is_hidden($resultsList, false);
// 			} else {
// 				is_hidden($resultNotFound, false);
// 				is_hidden($resultFound, true);
// 				is_hidden($resultsList, true);
// 			}

// 			// if (response.spelling) {
// 			// 	var htmlSuggestions = '<a href="'
// 			// 		+ ((Config.language == "ar") ? "/ar" : "")
// 			// 		+ "/search-results?term="
// 			// 		+ response.spelling.correctedQuery
// 			// 		+ '" class="color">'
// 			// 		+ response.spelling.correctedQuery
// 			// 		+ '</a>';

// 			// 	$suggestionTerm.html(htmlSuggestions);
// 			// 	is_hidden($suggestion, false);
// 			// }

// 			function generate_result_control(item) {
// 				return '<li class="item">'
// 					+ '<a href="' + item.link + '" class="link">' + item.title + '</a>'
// 					+ '<div class="text">' + item.htmlTitle + '</div>'
// 					+ '</li>'
// 			}

// 			function is_hidden(element, boolean) {
// 				if (boolean) {
// 					$(element).addClass('hidden');
// 				}
// 				else {
// 					$(element).removeClass('hidden');
// 				}
// 			}

// 			var result = [];
// 			for (var i = 1; i < response.searchInformation.totalResults; i++) {
// 				result.push(i);
// 			}
// 			if(done) {
// 				done(result);
// 			}
// 		}
// 	});
// }

// function search(term) {
// 	var initStart = 1;
// 	if($('#pagination-container').data().pagination	){
// 		$('#pagination-container').pagination('destroy')
// 	}
// 	$('#pagination-container').pagination({
// 		dataSource: function (done) {
// 			console.log('setup dataSource')
// 			searchApi(term, initStart, done)
// 		},
// 		callback: function (data, pagination) {

// 			var start = data[0];
// 			if(start != initStart) {
// 				console.log('get next page ' + start)
// 				console.log($('#pagination-container').data().pagination, data);
// 				searchApi(term, start);
// 			}

// 			initStart = undefined;
// 		}
// 	});
// }

// function simpleTemplating(data) {
// 	var html = '<ul>';
// 	$.each(data, function (index, item) {
// 		html += '<li>' + item + '</li>';
// 	});
// 	html += '</ul>';
// 	return html;
// }

