import PerfectScrollbar from 'perfect-scrollbar'

function init_perfect_scrollbar() {
	$('.scrollbarContainerJS:not(.firstDropdownMenu.wideMenu)').each(function () {
		const ps = new PerfectScrollbar($(this)[0], {
			wheelPropagation: false,
			wheelSpeed: 0.4,
			suppressScrollX: true
		});
	});
}

$(function () {
	init_perfect_scrollbar();
});

