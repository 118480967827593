function init_main_carousel() {
	$('.mainCarouselJS').each(function () {
		$(this).slick({
			rtl: window.seminar.direction ? true : false,
			mobileFirst: true,
			fade: true,
			dots: true,
			arrows: false,
			autoplay: true,
			autoplaySpeed: 7000,
			speed: 1500,
			rows: 0,
			customPaging: function (slider, i) {
				var thumb = $(slider.$slides[i]).find('img').attr('id');
				return '<button type="button" aria-labelledby="' + thumb + '"></button>';
			}
		});
	});
}

function init_news_carousel() {
	$('.newsCarouselJS').each(function () { 
		$(this).slick({
			rtl: window.seminar.direction ? true : false,
			mobileFirst: true,
			dots: false,
			arrows: true,
			autoplay: true,
			autoplaySpeed: 7000,
			rows: 0,
            prevArrow: '<button type="button" class="navArrow slick-prev" aria-label="לשקופית הקודמת"></button>',
            nextArrow: '<button type="button" class="navArrow slick-next" aria-label="לשקופית הבאה"></button>'
		});
	});
}

function init_events_carousel() {
    $('.eventsCarouselJS').each(function () {
        let $this = $(this);
		$this.slick({
			rtl: window.seminar.direction ? true : false,
			mobileFirst: true,
			dots: false,
			arrows: true,
            centerMode: true,
            autoplay: $this.data().slickAutoplay,
            centerPadding: '30px',
            prevArrow: `<button type="button" class="navArrow slick-prev" aria-label="לשקופית הקודמת" data-layer-on-submit data-category='עמודי תואר ותכניות לימודים' data-action='טוב לדעת דפדוף' data-label=''  data-event = 'auto_event'></button>`,
            nextArrow: `<button type="button" class="navArrow slick-next" aria-label="לשקופית הבאה" data-layer-on-submit data-category='עמודי תואר ותכניות לימודים' data-action='טוב לדעת דפדוף' data-label=''  data-event = 'auto_event'></button>`,
			responsive: [
				{
				
					breakpoint: window.seminar.screenSm,
					settings: {
						centerMode: false,
						rows: 1,
						slidesPerRow: 1,
						centerPadding: '0',
					}
				},
				{
				
					breakpoint: window.seminar.screenMd,
					settings: {
						centerMode: false,
						rows: 2,
						slidesPerRow: 2,
						centerPadding: '0',
					}
				}
			]
		});
	});

}

function init_sync_carousel_in_modal() {
    var index = 1;
	$('.thumbCarouselSectionJS').each(function () {
		var $t = $(this);
		var $for = $t.find('.thumbCarouselFor');
		var $nav = $t.find('.thumbCarouselNav');
		var $gallery = $t.find('.galleryCarousel');
		var $galleryModal = $t.find('.galleryModal');
		var $forAnd$gallery = $for.add($gallery);
		var $navAnd$gallery = $nav.add($gallery);
		var $forAnd$nav = $for.add($nav);
		var currentSlide;
        var dupForSetting;
        var $galleryId = $galleryModal.attr('id');

        $for.find('.item').each(function () {
            var $s = $(this);
            var $contentDataTarget = $s.find('.content').attr('data-target');
            $s.find('.content').attr('data-target', $contentDataTarget + '-' + index);
        });
        $galleryModal.attr('id', $galleryId + '-' + index);

		var forSettings = {
			rtl: window.seminar.direction ? true : false,
			mobileFirst: true,
			arrows: false,
			fade: true,
			draggable: false,
			rows: 0,
			asNavFor: $navAnd$gallery
		}

		if ($nav.hasClass('pagerNumbers')) {
			var navSettings = {
				rtl: window.seminar.direction ? true : false,
				mobileFirst: true,
				arrows: true,
				focusOnSelect: true,
				draggable: false,
				centerMode: true,
				centerPadding: '0',
				slidesToShow: 5,
				rows: 0,
				asNavFor: $forAnd$gallery,
				prevArrow: '<button type="button" class="navArrow centerBox slick-prev" aria-label="לשקופית הקודמת"></button>',
				nextArrow: '<button type="button" class="navArrow centerBox slick-next" aria-label="לשקופית הבאה"></button>'
			}
		}
		else {
			var navSettings = {
				rtl: window.seminar.direction ? true : false,
				mobileFirst: true,
				arrows: true,
				focusOnSelect: true,
				centerMode: true,
				centerPadding: '30px',
				slidesToShow: 3,
				rows: 0,
				asNavFor: $forAnd$gallery,
				prevArrow: '<button type="button" class="navArrow centerBox slick-prev" aria-label="לשקופית הקודמת"></button>',
				nextArrow: '<button type="button" class="navArrow centerBox slick-next" aria-label="לשקופית הבאה"></button>',
				responsive: [
					{
						breakpoint: window.seminar.screenSm,
						settings: {
							centerPadding: '60px',
							slidesToShow: 5,
						}
					}
				]
			}
		}

		var gallerySettings = {
			rtl: window.seminar.direction ? true : false,
			mobileFirst: true,
			dots: false,
			arrows: true,
			fade: true,
			rows: 0,
			asNavFor: $forAnd$nav,
			prevArrow: '<button type="button" class="navArrow centerBox slick-prev" aria-label="לשקופית הקודמת"></button>',
			nextArrow: '<button type="button" class="navArrow centerBox slick-next" aria-label="לשקופית הבאה"></button>'
		}

		$for.slick(forSettings);

		$nav.slick(navSettings);

		$gallery.slick(gallerySettings);

		$galleryModal.on('shown.bs.modal', function () {
			$gallery.slick('setPosition');
		}).on('hide.bs.modal', function () {
			currentSlide = $for.slick('slickCurrentSlide');
			dupForSetting = forSettings;
			dupForSetting.initialSlide = currentSlide;

			$for.slick('unslick');
		}).on('hidden.bs.modal', function () {
			$for.slick(dupForSetting);
            });
        index++;
    });

}

$(function () {
	init_main_carousel();
	init_news_carousel();
	init_events_carousel();
	init_sync_carousel_in_modal();
});