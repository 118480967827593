import globalConfig from "./global";

let layout = document.getElementById('mainLayout');
let nodeId = layout ? layout.dataset.nodeId : "";
let template = layout ? layout.dataset.template : "";
function init_date_picker() {
	var date = new Date(new Date().getDate(), new Date().getMonth(), new Date().getFullYear());
	var min = ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + date.getFullYear();
	$('.datepickerJS').each(function () {
		$(this).datepicker({
			inputFormat: 'd/M/y',
			outputFormat: 'd/M/yy',
			min: min,
			gainFocusOnConstruction: false
		});
	});
}

function datePicker_multiple_dates() {
	var $inlineCalendarJs = $('.calendarJS');
	var notAccessedCalendar = $('.calendarContainer .notAccess');
	var count = 0;
	var currentMonth;
	var nextMonth;

	$inlineCalendarJs.each(function (index) {
		var $singleCalendar = $(this);
		$singleCalendar.datepicker({
			inline: $(this).closest('.dateWrapper'),
			gainFocusOnConstruction: false,
			inputFormat: 'd/M/y',
			outputFormat: 'd/M/yy',
		});

		// $singleCalendar.datepicker('daysOfWeekDisabled', [0, 1, 2, 3, 4, 5, 6]);
        $singleCalendar.datepicker('setDate', setDate('start', index));
        $singleCalendar.on('change', events_calendar_html_ajax);
	});

	setTimeout(function () {
		notAccessedCalendar.find('[tabindex="0"]').attr('tabindex', '-1');
		$('.calendarContainer').find('.datepicker-button').attr('tabindex', '-1').css('pointer-events', 'none');
	}, 1000);

	function debounce(func, wait, immediate) {
		var timeout;
		return function () {
			var context = this, args = arguments;
			var later = function () {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	}

	//For Buttons Click Prev or Next
	$('.nextPrevContainer .btnContainer').on('click', function () {
		if ($(this).hasClass('next')) {
			count++;
		}
		else if ($(this).hasClass('prev')) {
			count--;
		}

		$inlineCalendarJs.each(function (index) {
			$(this).datepicker('setDate', setDate('month', index))
		});

		highlight_events_with_classes();
		//events_calendar_html_ajax();
	});

	//For Keyboard KeyCode
	$('.dateWrapper').on('keyup', '.datepicker-calendar td', debounce(function () {
		currentMonth = convertDate();
		$(this).click();
		nextMonth = convertDate();
		if (currentMonth > nextMonth) {
			count--;
		}
		else if (currentMonth < nextMonth) {
			count++;
		}

		if (currentMonth != nextMonth) {
			notAccessedCalendar.each(function (index) {
				$(this).find('.calendarJS').datepicker('setDate', setDate('notAccess', index));

				highlight_events_with_classes();
				//events_calendar_html_ajax();
			});
		}
	}, 100));

	function convertDateMonthYear(returnType) {
		var d = $('#first').datepicker('getDate');
		var parts = d.toString().split(" ");
		var months = { Jan: "01", Feb: "02", Mar: "03", Apr: "04", May: "05", Jun: "06", Jul: "07", Aug: "08", Sep: "09", Oct: "10", Nov: "11", Dec: "12" };
		if (returnType == 'year') {
			return parts[3];
		} else if (returnType == 'month') {
			return months[parts[1]];
		}
	}

	function convertDate() {
		var d = $('.calendarJS').eq(0).datepicker('getDate');
		var parts = d.toString().split(" ");
		var months = { Jan: "01", Feb: "02", Mar: "03", Apr: "04", May: "05", Jun: "06", Jul: "07", Aug: "08", Sep: "09", Oct: "10", Nov: "11", Dec: "12" };
		return +months[parts[1]];
	}

	function setDate(control, index) {
		var month = parseInt(convertDateMonthYear('month'));
		var year = parseInt(convertDateMonthYear('year'));
		if (control == 'start' || control == 'month') {
			return new Date(new Date().getFullYear(), new Date().getMonth() + index + count, new Date().getDate());
		} else {
			if (month == 1) {
				return new Date(year, month + index, new Date().getDate());
			} else if (month == 12) {
				return new Date(year + 1, (month % 12) + index, new Date().getDate());
			} else {
				return new Date(year, (month % 12) + index, new Date().getDate());
			}

		}
	}
}

function events_calendar_html_ajax(e) {
	let date = $(e.target).datepicker("getDate");
	let selectedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
	console.log(selectedDate);
	var url = `/umbraco/Surface/EventsSurface/GetEventByDate?date=${selectedDate}&nodeId=${nodeId}`;
	let $eventsList = $('.eventsContainer');
    window.seminar.toggleLoader(true);
	$eventsList.load(url,()=> window.seminar.toggleLoader(false));
    
}

function highlight_events_with_classes() {

    var url = "/umbraco/Surface/EventsSurface/GetEventsDates";
    $.ajax({
        url: url,
        type: 'POST',
        contentType: 'application/json; charset=utf-8',
        data: "{'nodeId': '" + nodeId + "'}",
        cache: false,
        success: function (data) {
            allCalendars(data);
        },
        error: function (err) {
            console.log(err);
        }
    });
    function allCalendars(data) {
        var $eventsListobj = data;
        var $allCalendars = $('.dateWrapper .datepicker-calendar');
        var $inputCalendar;
        var $selectableDay = $('td.day');
        $allCalendars.each(function () {
            var $calendar = $(this);
            $inputCalendar = $('#' + $calendar.attr('aria-controls'));
            $calendar.find($selectableDay).each(function (index) {
                var $td = $(this);
                var string = convertDate($td.data('value'), $inputCalendar);
                for (var i = 0; i < $eventsListobj.length; i++)  //eventsListobj
                {
                    if (string.day.toString() == $eventsListobj[i].Day && string.month == $eventsListobj[i].Month && string.year == $eventsListobj[i].Year) {
                        if (window.seminar.direction) {
                            $td.addClass('eventDay').removeClass('unselectable').addClass('selectable').attr('aria-label', 'יש אירועים ביום זה');
                        } else {
                            $td.addClass('eventDay').removeClass('unselectable').addClass('selectable').attr('aria-label', 'There are events in this day');
                        }
                        return
                    }
                }
                if (!$td.hasClass('eventDay')) {
                    $td.addClass('unselectable').attr('tabindex', '-1');

                }
            });
        });
        function convertDate(day, calendar) {
            var d = calendar.datepicker('getDate');
            var parts = d.toString().split(" ");
            var months = { Jan: "01", Feb: "02", Mar: "03", Apr: "04", May: "05", Jun: "06", Jul: "07", Aug: "08", Sep: "09", Oct: "10", Nov: "11", Dec: "12" };
            return { year: parts[3], month: months[parts[1]], day: day };
        }
    }
   
    
}



$(function () {
    // if (template == "Events") {
        init_date_picker();
        datePicker_multiple_dates();
	//events_calendar_html_ajax()
	if (globalConfig.contentType === "events") {
		highlight_events_with_classes();
    }
       
    // }
});