import { createSelect } from "./selectpicker"
import PerfectScrollbar from 'perfect-scrollbar'

window.seminar.toggleLoader = window.seminar.toggleLoader || toggle_loader;

function toggle_loader(loadAnimation) {
    if (loadAnimation === true) {
        $(document.body).append('<div class="loaderContainer" id="loaderContainerJS" aria-label="התוכן נטען כעת" role="alert"><div class="spinnerBorder"></div></div>')

    } else {
        $('#loaderContainerJS').remove();
    }
}

function on_modal_open_add_space() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) { }
    else {
        $('.modal').on('show.bs.modal', function () {
            $('body').css('padding-right', '17px');
        }).on('hidden.bs.modal', function () {
            $('body').css('padding-right', '0');
        });
    }
}

function on_win_scroll_add_class_for_transition() {
    setTimeout(function () {
        $('#mainContent section').each(function () {
            var this_element = $(this);
            var win = $(window);
            var win_top = win.scrollTop();
            var win_bottom = win_top + win.height();
            var element_Top = this_element.offset().top;

            // element is in the window
            if (element_Top > win_top && element_Top < win_bottom) {
                this_element.addClass('move');
            }

            $(window).scroll(function () {
                this_element.each(function () {
                    var win_top = win.scrollTop();
                    var win_bottom = win_top + win.height();
                    var element_Top = this_element.offset().top;

                    if (win_bottom > element_Top + 200) {
                        $(this).addClass('move');
                    }
                });
            });
        });
    }, 1000);
}

function inline_backgrounds() {
    $.fn.inlinebackgrounds = function () {
        $.each(this, function (i, t) {
            var split = $(t).html().split('<br>');
            var output = '';
            $.each(split, function (i, o) {
                output += '<span class="backgroundContainer"><span class="backgroundText">' + o + '</span></span>';
            });
            $(t).html(output);
        });
    }

    $('.textBgJS').inlinebackgrounds();
}

function printWindow() {
    $('.printBtn').on('click', function () {
        window.print();
    });
}

function init_tooltip() {
    $('.rowSpanJS').popover({
        html: true,
        container: 'body',
        placement: 'top',
        trigger: 'click hover focus',
        template: '<div class="popover generalPopover" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>',
        content: function () {
            return $(this).find('.tooltipCol').html();
        }
    });
}

function count_textarea_characters() {
    $('.textareaJS').on('input', function () {
        var $t = $(this);
        var maxlength = $t.attr('maxlength');
        var currentLength = $t.val().length;

        if (currentLength <= maxlength) {
            $t.siblings('.textareaCounterJS').find('.number').text(currentLength);
        }
    });
};

function input_file_name_update() {
    $('.inputFileJS').on('change', function () {
        var $t = $(this);
        var fileName = $t.val();

        $t.siblings('.inputFileNameJS').html(fileName);
    });
}

function scrollTop_anchor_linking() {
    $('.anchorJS').click(function () {
        $(this).addClass('active').closest('.item').siblings().find('.anchorJS').removeClass('active');
        var $anchor = $($(this).data('target'));

        $('html,body').animate({
            scrollTop: $anchor.offset().top - $('#mainHeaderJS').outerHeight()
        }, 1000);
        return false;
    });
}

function scrollTo_anchor_linking() {
    $('.rteContent').find('a').each(function () {
        var $t = $(this);
        if ($t.attr('data-anchor')) {
            $t.on('click', function () {
                var $anchor = $($t.data('anchor'));

                $('html,body').animate({
                    scrollTop: $anchor.offset().top - ($('#mainHeaderJS').outerHeight() + 30)
                }, 1000);
                return false;
            })
        }
    })
}



function ajaxify_form() {
    $('form[data-ajax-form]').on('submit', ajaxify);

    function ajaxify(event) {
        var $form = $(this);
        if (shouldAbort()) {
            return;
        }
        event.preventDefault();
        window.seminar.toggleLoader(true);
        dataLayer.push({ 'Category': 'עמוד ראשי', 'Action': 'צור קשר', 'Label': 'שלח טופס', 'event': 'auto_event' });
        send();
        function shouldAbort() {
            return !$form.valid() || $form.data().loading;
        }
        function send() {
            var replaceWith = $form.data().afterSending;
            var serializedForm = $form.serialize();
            var url = $form.attr('action');
            var method = $form.attr('method') || 'POST';
            $form.data('loading', true).addClass('is-loading');;
            $.ajax({ url: url, data: serializedForm, type: method })
                .then(function (d) {
                    if (d.success || d.Success) {

                        if (replaceWith) {
                            $(replaceWith).removeClass("hidden");
                            $form.html($(replaceWith));

                            return;
                        }
                    }


                    alert("An Error has occured - please contact site manager");
                }, function (e) {

                })
                .always(function () {
                    $form.data('loading', false).removeClass('is-loading');
                    window.seminar.toggleLoader(false);
                });
        }


    }
}

function arrow_out_of_range() {
    if ($('.scrollBtnJS').length > 0) {
        var arrowHeight = $('.scrollBtnJS').offset().top;
        var sectionFromTop = $('.jobsSection').offset().top;
        var sectionHeight = $('.jobsSection').height();
        if (arrowHeight < sectionFromTop || arrowHeight > sectionFromTop + sectionHeight) {
            $('.scrollBtnJS').addClass('outRange')
        } else {
            $('.scrollBtnJS').removeClass('outRange')
        }
        $(window).on('scroll', function (event) {
            var arrowHeight = $('.scrollBtnJS').offset().top;
            var sectionFromTop = $('.jobsSection').offset().top;
            if (arrowHeight < sectionFromTop) {
                $('.scrollBtnJS').addClass('outRange')
            } else {
                $('.scrollBtnJS').removeClass('outRange')
            }
        });
    }
}

function focus_on_collapse_open() {
    $(document).on('shown.bs.collapse', '[data-focus]', function () {
        var $t = $(this);
        $($t.attr('data-focus')).attr('tabindex', '-1').focus();
    });
}

function rte_table_responsive() {
    var $table = $('.rteResponsiveTable');
    $table.each(function () {
        var dataContent = [];
        var $t = $(this);
        var $tableTh = $t.find('thead th');
        if ($tableTh.length > 0) {
            $tableTh.each(function () {
                var $th = $(this);
                dataContent.push($th.text());
            });

            $t.find('tbody tr').each(function () {
                var $tr = $(this);
                $tr.find('td').each(function (index) {
                    var $td = $(this);
                    $td.attr('data-content', dataContent[index] + ':');
                });
            });
        }
    });
}

function cascade_plans() {
    let $program = $('#selectDegree'), $plan = $('#selectProgram');
    let $form = $program.closest('form');
    let $grandchildrenSortList = new Array();
    if (!window.academicPrograms) {
        return;
    }
    $form.on('submit', e => {
        e.preventDefault();
        window.location.href = e.target.action;
    });
    $program.on('change',
        (e) => {
            var val = e.target.value;
            var item = window.academicPrograms.find(function (p) {
                return p.Id === parseInt(val);
            });
            $plan.empty();

            item.Children.forEach((items, itemIndex) => {
                if (items.GrandChildren && items.GrandChildren.length !== 0) {
                    items.GrandChildren.forEach((grandChildItem, index) => {
                        $grandchildrenSortList.push(grandChildItem);
                    });
                }

            });
            $grandchildrenSortList.sort(function (a, b) {
                var nameA = a.Name.toUpperCase();
                var nameB = b.Name.toUpperCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }

                return 0;
            });


            if ($grandchildrenSortList.length !== 0) {
                $grandchildrenSortList.forEach((grandChild, index_) => {

                    $plan.append(`<option value="${grandChild.Url}">${grandChild.Name}</option>`);
                });
            }

            $grandchildrenSortList = [];
            $plan.selectpicker('refresh');


        });
}

function expand_collapse_brief() {
    $('.js-expand-brief').each(function () {
        let $this = $(this);
        let $brief = $this.siblings('[data-brief]');
        $this.on('hide.bs.collapse', () => $brief.removeClass('hidden'));
        $this.on('show.bs.collapse', () => $brief.addClass('hidden'));
    });
}

function share_on_click() {
    $(document).on('click', '.shareLinkJQ', function () {
        var $t = $(this);
        var href = $t.attr('href');
        var pathname = decodeURI(window.location.pathname);
        var baseUrl = decodeURI(window.location.href);

        if ($t.hasClass('gmail')) {
            var newHref = href.replace('<SUBJECT>', pathname).replace('<URL>', baseUrl);
            $t.attr('href', newHref);
        }
        else {
            var newHref = href.replace('<URL>', baseUrl);
            $t.attr('href', newHref);

            window.open(newHref, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=500,width=600');
            return false;
        }
    });
}


function headerClassToggle() {
    $('#headerBottomJS').on('shown.bs.collapse', function (e) {
        if (e.target.id === 'headerBottomJS') {
            $('.mainHeader').addClass('active');
        }
    })

    $('#headerBottomJS').on('hidden.bs.collapse', function (e) {
        if (e.target.id === 'headerBottomJS') {
            $('.mainHeader').removeClass('active');
        }
    })

}


function onSubmit() {
    let $program = $('#selectDegree'), $plan = $('#selectProgram');
    $('.subBtn').on('click', function () {
        let $form = $program.closest('form');
        $form.attr('action', $plan.find('option:selected').val());
        dataLayer.push(
            {
                'Category': 'גישה מהירה',
                'Action': $program.find('option:selected').text(),
                'Label': $plan.find('option:selected').text(),
                'event': 'auto_event'
            });
    });
}



function sendDataLayerEventsForForms() {
    var $attrData = $('[data-layer-on-submit]');
    $attrData.each(function () {
        var $t = $(this);
        if ($t.is('section')) {
            $t.find('form').on('submit', onsubmit);
        } else if ($t.is('a') || $t.is('button')) {
            $t.on('click submit', submitNoForm);
        } else if ($t.is('form')) {
            $t.on('submit', onsubmit);
        }
    })

    function onsubmit() {
        var frm = $(this);
        if (frm.valid() && dataLayer) {
            window.seminar.toggleLoader(true);
            dataLayer.push(getDataLayerInfo(frm || {}));
        }
    }
    function submitNoForm() {
        var $aData = $(this);
        if (dataLayer) {
            dataLayer.push(getDataLayerInfo($aData || {}));
        }
    }
    function getDataLayerInfo($elem) {
        var $d = $elem.closest("[data-layer-on-submit]");
        if ($d.length === 0) {
            return null;
        }
        return remapDataLayerObejctKeys($d.data());;
    }
}



function addScrollbarToSideNav() {
    if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) && window.innerWidth >= 994) {
        if ($('.sideWrapper .scrollbarContainer').length) {
            $('.sideWrapper .scrollbarContainer').addClass('scrollbarContainerJS ps ps__rtl ps--active-y');
            init_perfect_scrollbar();
        }
    }
}

function init_perfect_scrollbar() {
    var $scrollbarContainer = $('.sideWrapper .scrollbarContainer');
    const ps = new PerfectScrollbar($scrollbarContainer[0], {
        wheelPropagation: false,
        wheelSpeed: 0.4,
        suppressScrollX: true
    });
}

function topNavigationFocusClass() {
    $('.firstDropdownMenu').each(function () {
        var $t = $(this);
        $t.on('click', function (e) {
            $t.closest('.item').addClass('focused').siblings().removeClass('focused');
        });

    })

    $('.mainNav .firstMenu .item').each(function () {
        var $li = $(this);
        $li.siblings().each(function () {
            var $bLi = $(this);
            $bLi.hover(function () {
                $li.removeClass('focused')
            })
        })
    })
}


$(function () {
    on_modal_open_add_space();
    on_win_scroll_add_class_for_transition();
    inline_backgrounds();
    printWindow();
    init_tooltip();
    count_textarea_characters();
    input_file_name_update();
    scrollTop_anchor_linking();
    scrollTo_anchor_linking();
    arrow_out_of_range();
    focus_on_collapse_open();
    rte_table_responsive();
    ajaxify_form();
    cascade_plans();
    share_on_click();
    expand_collapse_brief();
    headerClassToggle();
    onSubmit();
    sendDataLayerEventsForForms();
    addScrollbarToSideNav();
    topNavigationFocusClass();
});

function remapDataLayerObejctKeys(data) {
    // all keys to lowercase
    var key, keys = Object.keys(data);
    var n = keys.length;
    var newobj = {}
    while (n--) {
        key = keys[n];
        newobj[key.toLowerCase()] = data[key];
    }
    // safely remap
    return { 'Category': newobj.category, 'Action': newobj.action, 'Label': newobj.label, 'event': newobj.event }
}

$(document).ready(function () {
    $('.rteContent table').wrap('<div class="table-wapper"></div>');
})
