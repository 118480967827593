const PLAN_STATE_TYPE = 'ACADEMIC_PLAN';
const PROGRAM_STATE_TYPE = 'ACADEMIC_PROGRAM';
let stateTracking = false;
let $section = $('#bannerMenuSection');
let $collapse = $section.find('#bannerMenuCollapse');
let $cont = $collapse.find(".modal-dialog");
let $btn = $section.find('.bannerMenuBtnJS');
let $closeButton = $cont.find('[data-dismiss=modal]');

const isDesktop = () => $(window).width() >= window.seminar.screenMd;


function toggle_banner_menu_collpase_or_modal() {
    $btn.on('click', async e => {
        await load(e);
        var $t = $(e.currentTarget);
        var $li = $t.parent();
        var liIndex = $li.index();
        let dataToPush = {
            'Category': 'עמוד ראשי',
            'Action': 'באנר עליון',
            'Label': $btn.get(liIndex).innerText,
            'event': 'auto_event'
        }
        if (isDesktop()) {
            $t.attr('tabindex', '0').attr('aria-expanded', true);
            $li.addClass('active').siblings().removeClass('active').find('.content').attr('tabindex', '-1').attr('aria-expanded', false);
            $collapse.collapse('show');
            $collapse.find('.facultySection').attr('id', 'facultySection' + liIndex);
        } else {
            $collapse.addClass('modal');
            await load(e);
            $collapse.modal('show');
        }
        dataLayer.push(dataToPush);
    });


    $closeButton.on('click', (e) => {
        const href = e.target.href;
        pushState({ type: PROGRAM_STATE_TYPE, href: href }, href);
    });
}

function pushState(state, href) {
    history.pushState(state, '', href);
}
function replaceState(state, href) {
    history.replaceState(state, '', href);
}

function initStateTracking() {

    stateTracking = true;
    alignInitialState();
    window.addEventListener('popstate', onStateChanged);

    function alignInitialState() {
        let $currentHtml = $cont.find('.facultySection');
        if ($currentHtml.length) {
            replaceState({ type: PLAN_STATE_TYPE, html: $currentHtml[0].outerHTML, href: location.href }, location.href);
            isDesktop() || $collapse.modal("show");

        } else {
            replaceState({ type: PROGRAM_STATE_TYPE, href: location.href }, location.href);
        }
    }
    function onStateChanged(e) {
        if (!e.state.type) {
            return;
        }
        if (e.state.type === PLAN_STATE_TYPE) {
            replaceHtml(e.state.html);
            isDesktop() ? $collapse.collapse("show") : $collapse.modal("show");
        }
        if (e.state.type === PROGRAM_STATE_TYPE) {
            isDesktop() ? $collapse.collapse("hide") : $collapse.modal("hide");
        }
    }
}

async function load(e) {
    try {
        if (!e) {
            return;
        }
        e.preventDefault();
        const href = e.currentTarget.href;
        window.seminar.toggleLoader(true);
        const html = await $.get(href);
        replaceHtml(html);
        pushState({ type: PLAN_STATE_TYPE, html: html, href: href }, href);

    } finally {
        window.seminar.toggleLoader(false);
    }

}

function replaceHtml(html) {
    $cont.find('.facultySection').remove();
    $cont.append(html);
}

function on_shown_banner_menu_collapse_or_modal_reinit_carousel() {
    $collapse.on('show.bs.collapse show.bs.modal', function () {
        $section.addClass('active');
    }).on('shown.bs.collapse shown.bs.modal', function () {
        var $carousel = $collapse.find('.carouselContainer:visible');
        if ($carousel.hasClass('slick-slider')) {
            $carousel.slick('setPosition');
        }
    }).on('hide.bs.collapse hide.bs.modal', function () {
        $section.removeClass('active');
        $section.find('.bannerMenuList .item .link').removeAttr('tabindex');
        $section.find('.item.active').removeClass('active').find('.bannerMenuBtnJS').focus();
    });
}

function move_focus_on_keyboard_arrows_tabs() {
    $btn.on('keydown', function (e) {
        var $items;
        var $ul = $btn.closest('.navTabsJS');
        var index;
        var k = e.which || e.keyCode;

        if (!/(37|38|39|40)/.test(k)) return;

        $items = $ul.find('.bannerMenuBtnJS');
        index = $items.index($items.filter(':focus'));

        if (k == 38 || k == 39) index--;    // up & left
        if (k == 37 || k == 40) index++;    // down & right

        if (index < 0) index = $items.length - 1;
        if (index == $items.length) index = 0;

        var nextTab = $items.eq(index);
        if (nextTab.attr('role') === 'tab') {
            nextTab.focus();
        }

        e.preventDefault();
        e.stopPropagation();
    });
}

$(function () {
    toggle_banner_menu_collpase_or_modal();
    on_shown_banner_menu_collapse_or_modal_reinit_carousel();
    move_focus_on_keyboard_arrows_tabs();
    initStateTracking();
});

export { PROGRAM_STATE_TYPE, PLAN_STATE_TYPE, stateTracking }