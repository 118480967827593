import "jquery";
import "babel-polyfill";
import "bootstrap";
import "popper.js";
import "bootstrap-select";
import "picturefill";
import "slick-carousel";
import "jquery-validation";
import "jquery-validation-unobtrusive";
import "jquery-sked-tape"

import "./scripts/global";
import "./scripts/reset";
import "./scripts/selectpicker";
import "./scripts/scrollbar";
import "./scripts/accessibilityPanel";
import "./scripts/carousels";
import "./scripts/bannerMenu";
import "./scripts/header";
import "./scripts/search";
import "./scripts/schedule";
import "./scripts/scripts";
import "./scripts/jobs";

import "./styles/stylesRte.scss";
import "./styles/styles.scss";

if (!window.jQuery) {
    window.jQuery = window.$ = require("jquery");
}

if (window.seminar.direction) {
    require("ab-datepicker/js/locales/he");
}
require("ab-datepicker");
require("./scripts/datepicker");
//require("paginationjs");