import PerfectScrollbar from 'perfect-scrollbar'

function init_sked_tape() {
    function today(hours, minutes) {
        let date = new Date();
        date.setHours(hours, minutes, 0, 0);
        return date;
    }
    $('.js-scheduler').each(function () {
        let $scheduler = $(this);
        let days = $scheduler.data("schedule").Days;
        let locationArr = [];
        for (var i = 0; i < days.length; i++) {
            let value = {
                id: days[i],
                name: days[i]
            }
            locationArr.push(value);

        }
        let items = $scheduler.data("schedule").Items;
        let eventsArr = [];
        for (var i = 0; i < items.length; i++) {
            let value = {
                name: items[i].CourseName,
                location: items[i].DayOfWeek,
                start: items[i].StartTime ? new Date(items[i].StartTime) : "",
                end: items[i].EndTime ? new Date(items[i].EndTime) : "",
                className: items[i].IsMandatoryCourse ? 'mandatory' : 'notMandatory',
            }
            eventsArr.push(value);

        }
      
        let $sked = $scheduler.skedTape({
            // caption: $scheduler.data("schedule").Day,
            start: today(7, 0),
            end: today(22, 0),
            showDates: false,
            showEventTime: true,
            showPopovers: 'always',
            disabled: true,
            locations: locationArr,
            events: eventsArr,
            postRenderEvent($el, event) {
                $el.attr('tabindex', 0);
                if (event.className == 'mandatory') {
                    $el.find('br').first().before("<span class='must'> (קורס חובה) </span>");
                }
                $el.find('br').first().after("<span class='time'> יום " + event.location + " - </span>");

                let $scrollContainer = $(this)[0].$el.find('.sked-tape__time-frame');

                if (!$scrollContainer.hasClass('ps')) {
                    const ps = new PerfectScrollbar($scrollContainer[0], {
                        wheelPropagation: false,
                        suppressScrollY: true,
                        useBothWheelAxes: true,
                    });
                }
            }
        });
    })



}


$(function () {
    init_sked_tape();
});







