import PerfectScrollbar from 'perfect-scrollbar'

var $selectpicker = $('.selectpicker');

function init_select() {
	$selectpicker.selectpicker({
		mobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ? true : false
	});

	$selectpicker.each(function (index, element) {
		var $t = $(element);
		var $selectChildren = $t.siblings('.dropdown-menu').find('ul.inner li');
		var $scrollContainer = $t.siblings('.dropdown-menu').find('div.inner');
		var $ddmenu = $t.siblings('.dropdown-menu');

		$t.on('shown.bs.select', function () {
			if ($scrollContainer.closest('.quickAccessForm').length) {
				$scrollContainer.css('max-height','400px');
				$ddmenu.css('max-height','400px');
			}
			
			if (!$scrollContainer.hasClass('ps--active-y')) {
				const ps = new PerfectScrollbar($scrollContainer[0], {
					wheelPropagation: false,
					wheelSpeed: 0.4,
					suppressScrollX: true
				});
			
			}
			$selectChildren.attr('role', 'presentation');
		}).on('loaded.bs.select', function () {
			var ariaLabelValue = $t.attr('data-label');
			var $selectBtn = $t.closest('.bootstrap-select').find('.btn');
			$selectBtn.attr('aria-label', ariaLabelValue);
		});
	});

	$(document).on('click', '.bootstrap-select .ps__rail-y', function (e) {
		e.stopPropagation();
	});
}

function scrollTop_with_bootstrap_select() {
	$('.js-staffdepartment-select').on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
		var $anchor = $(`#${e.target.value}`);

		$('html,body').animate({
			scrollTop: $anchor.offset().top - 130
		}, 1000, function () {
			$anchor.attr('tabindex', '-1').focus();
		});
	});
}

$(function () {
	init_select();
	scrollTop_with_bootstrap_select();
});