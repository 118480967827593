(function ($, window) {
    const $buttonSelector = ".js-load-job[data-id]";
    const $target = $(".js-job-description-container");



    $($buttonSelector).on("click", onclick);


    function onclick(e) {
        let url = e.target.dataset.href;
        if (url !== $target.data().url) {
            window.seminar.toggleLoader(true);
            $target.load(url, function(){
                $target.data("url", url);
                window.seminar.toggleLoader(false);
            });
         
        }
       
    }



})(jQuery,window);