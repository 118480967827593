const globalConfig = window.globalConfig || (window.globalConfig = {});


$(function () {
    const bodyElem = document.getElementsByTagName("body")[0];
    const data = bodyElem.dataset;

    Object.assign(window.globalConfig, data);
})

export default globalConfig;

$('.firstFooterMenu .secondFooterMenu-expander').on('click', function () {
    let menu = $(this).next('.secondFooterMenu');
    //open menu or close menu
    if(!menu.hasClass('opened')){
        $(this).css('transform','rotate(135deg)')
        let neededheight = menu.outerHeight();
        menu.css('height', 0);
        menu.toggleClass('opened')
        menu.animate({
            height:neededheight
        },  
            500
        )
    }
    else{
        $(this).css('transform','rotate(-45deg)')
        menu.animate({
            height:0
        },  
        500, 
        function(){
            menu.toggleClass('opened');
            menu.css('height','');
        }
    );
       
    }
})